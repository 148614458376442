import * as React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../../components/layout'
import { ProductCategoryCards } from '../../components/commerce/category/ProductCategoryCards'

const ProductsPage = () => {
  return (
    <Layout>
      <Row className="mt-5">
        <Col md={6}>
          <p>
            We supply a wide range of quality rural farm products such as fencing and stock handling equipment. 
            We also supply a range of steel for building and fabrication.
          </p>
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <div>
            <button className="primary">
              <FontAwesomeIcon icon={faNewspaper} />Latest Catalogue
            </button>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12}>
          <ProductCategoryCards />
        </Col>
      </Row>
    </Layout>
  )
}

export default ProductsPage

export const Head = () => (
  <>
    <title>Products Page | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe. Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, roofing and building accessories." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)
